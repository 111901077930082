import React, { Fragment, useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';

import "./assets/css/bdp.css";
import "./assets/css/modal.css";

import SectionInfo from './components/SectionInfo/SectionInfo';
import GridItem from './components/GridItem/GridItem';
import { ourValueData, customerValueData, faqItems, brandPartnerLogos } from './data';

import HeroVideo from "../BrandDesignPartner/assets/img/hero.mp4";
import Cross from "./assets/img/cross.svg";
import Check from "./assets/img/check.svg";
import DarklionPro from "./assets/img/darklion-pro.svg";
import DarklionAid from "./assets/img/darklion-aid.svg";
import DarklionEdu from "./assets/img/darklion-edu.svg";
import DarklionTbc from "./assets/img/darklion-tbc.svg";
import PlayBtn from "./assets/img/brand-partner/play-btn.svg";
import OurTeam from "../assets/img/team/culture/darklion-studio-team-group1.jpg";
import { useForm } from 'react-hook-form';
import { useRef } from 'react';



function BrandDesignPartner({ mouseFunction, mouseOutFunction, mouseInFaqFunction, mouseFaqOutFunction, modalVisibleVar, modalClick, onMouseEnterBelowButton }) {
    const SITE_KEY = "6LffOhAqAAAAAHwDnPEFktp6Xq11WY7b45mYVTxo";
    /* Google ReCaptcha V3 */
    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }

        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
            console.log("Script loaded!");
        });
    }, []);

    const [activeIndex, setActiveIndex] = useState(null);

   
    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const [companyPhaseValue, setCompanyPhaseValue] = useState('');
    const companyPhaseClick = (e) => {
        setCompanyPhaseValue(e.target.innerHTML);
        setValue('company_phase', e.target.innerHTML, { shouldValidate: true })
        console.log(watch(), errors);
    }

    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm({ mode: "onChange" });
    const [fieldsFocused, setFieldsFocused] = useState({
        fullname: false,
        email: false,
        company_name: false,
        company_phase: false,
        role: false,
        location: false,
        about_project: false
    });

    // Use useEffect to set initial values and update focus state
    useEffect(() => {
        // Function to check if a field has content
        const hasContent = (field) => !!register[field]?.value;

        // Check each field initially
        Object.keys(fieldsFocused).forEach(field => {
            if (hasContent(field)) {
                setFieldsFocused(prevState => ({
                    ...prevState,
                    [field]: true
                }));
            }
        });
    }, []);

    const [submission, updateSubmission] = useState(false)
    const [requestSubmissionInProgress, setRequestSubmissionInProgress] = useState(false);



    const onSubmit = async (data) => {
        setRequestSubmissionInProgress(true)
        let googleTokenResponse = '';

        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(async (token) => {

                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                const raw = JSON.stringify({
                    "response": token
                });

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow"
                };

                googleTokenResponse = await fetch("https://admin.darklion.studio/api/capctha-verification", requestOptions)
                    .then((response) => response.text())
                    .then(async (result) => {
                        result = JSON.parse(result)
                        if (result.success == true) {
                            if (result.data.score >= 0.5) {
                                const myHeaders = new Headers();
                                myHeaders.append("Content-Type", "application/json");
                                const raw = watch();
                                console.log(raw);

                                const requestOptions = {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        'name': raw.fullname,
                                        'email': raw.email,
                                        'company_name': raw.company_name,
                                        'company_phase': raw.company_phase,
                                        'role': raw.role,
                                        'location': raw.location,
                                        'about_project': raw.about_project,
                                        'target_form': 'Brand Partner Page'
                                    }),
                                    redirect: "follow"
                                };

                                try {
                                    let a = fetch("https://admin.darklion.studio/api/bdpcontactdata", requestOptions)
                                        .then((response) => console.log(response.json()))
                                        .then((result) => {
                                            updateSubmission(true)
                                            setTimeout(() => {
                                                updateSubmission(false)
                                                reset();
                                                setValue('role', null);
                                                setRequestSubmissionInProgress(false)
                                            }, 1500);
                                        })
                                        .catch((error) => console.error(error));
                                } catch (error) {
                                    console.log(error);
                                }
                            }
                        }
                    }).catch((error) => console.error(error));
            })
        })
    };

    const [companyPhaseDropDownToggle, setCompanyPhaseDropDownToggle] = useState(false);

    const companyPhaseDropDownClick = () => {
        setCompanyPhaseDropDownToggle(!companyPhaseDropDownToggle);
    }

    const [videoModalToggle, setVideoModalToggle] = useState(false);
    const modalVideoContainer = useRef("modalVideoContainer");

    const closeModal = () => {
        setVideoModalToggle(!videoModalToggle);
        modalVideoContainer.current.innerHTML = '';
    }

    /* Play Video on Modal */
    const videoBackGround = useRef("videoBackGround");
    const playVideoModal = () => {
        // Event listener to open modal
        setVideoModalToggle(!videoModalToggle);
        const videoClone = videoBackGround.current.cloneNode(true);
        videoClone.setAttribute('controls', 'controls');
        videoClone.removeAttribute('muted');

        // Clear previous video if any
        modalVideoContainer.innerHTML = '';
        modalVideoContainer.current.appendChild(videoClone);

    }

    return (
        <Fragment>
            <div class="dl-wrapper _brand_design_partner">

                {/* Banner  */}
                <section class="dl-block _hero ">
                    <div class="dl-container border-b-grey">
                        <div class="_hero_content">
                            <h1 class="_hero_brand">
                                DARKLION STUDIO
                            </h1>
                            <div class="_hero_tagline">
                                <div class="_hero_tagline_text mB40">
                                    YOUR
                                    <span class="glitch _shadow-left _hero_tagline_text-brand" data-text="BRAND">BRAND</span>

                                    <span class="glitch block _hero_tagline_text-design" data-text="DESIGN"> DESIGN</span>

                                    <span class="glitch _hero_tagline_text-partner" data-text="PARTNER .">PARTNER .</span>
                                </div>
                            </div>
                            <p class="text-center">
                                To become a brand, your vision has to go through 4 business stages, and the foundation of each
                                stage sets the
                                benchmark for growth and evolution into the next stage. Failing to establish a strong foundation
                                & building on top of it ends up in losing valuable time, energy, resources and money. This is
                                where our brand design partner services program help potential brands or visionaries in pursuit
                                of building the brands.
                            </p>
                        </div>
                        <div class="_hero_media">
                            <video loop autoPlay muted playsInline class="_hero_media_file" ref={videoBackGround}>
                                <source src="https://darklion-studio.s3.ap-south-1.amazonaws.com/bdp-video/bdp-animation-video.mp4" type="video/mp4" />
                                <meta itemprop="description" content="An Idea to Brand Explanation | Brand Design Partner | DarkLion Studio" />
                                Your browser does not support the video tag.
                            </video>
                            <a href="javascript:void(0);" id="videoThumbnail" onClick={playVideoModal} title="Play video">
                                <img src={PlayBtn} alt="Play video" />
                            </a>
                        </div>
                        <div id="videoModal" class="modal" style={{ display: videoModalToggle ? 'block' : 'none' }}>
                            <div class="modal-content">
                                <span class="close-video" onClick={closeModal}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                        <path d="M37.463 12.1724L12.1723 37.463" stroke="#fff" stroke-width="2.84672">
                                        </path>
                                        <path d="M12.1722 12.1721L37.4629 37.4628" stroke="#fff" stroke-width="2.84672">
                                        </path>
                                    </svg>
                                </span>
                                <div ref={modalVideoContainer} id='modalVideoContainer'></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* challenges and pain  */}
                <section class="challenges-and-pains">
                    <div class="dl-container section-padding border-b-grey">
                        <SectionInfo
                            title="Challenges and Pains"
                            content="Your brand's journey is filled with unique challenges and obstacles that can impede progress. Addressing these barriers promptly is crucial, as failure to do so leads to stagnation and wasted resources. Our model proactively tackles these challenges to ensure long-term success and competitiveness."
                            className="mB90"
                            paragraphClass="text-center"
                        />

                        <div class="media-wrapper mB90">
                            <Player
                                src="https://lottie.host/283a35a1-e561-49df-8ea1-a3ba4feffc87/9N2PSQGUTu.json"
                                background="transparent"
                                speed={1}
                                style={{ width: '100%', height: 'auto' }}
                                loop
                                autoplay
                            />
                        </div>
                        <div class="characteristics">
                            <ul>
                                <ScrollAnimation animateIn="fadeIn" duration={3} delay={2100}>
                                    <li>
                                        <img src={Cross} alt="icon" />
                                        Over-hiring of resources
                                    </li>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn="fadeIn" duration={3} delay={1200}>
                                    <li>
                                        <img src={Cross} alt="icon" />
                                        Financial and management burden
                                    </li>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn="fadeIn" duration={3} delay={2400}>
                                    <li>
                                        <img src={Cross} alt="icon" />
                                        Unaligned team with vision and missions
                                    </li>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn="fadeIn" duration={3} delay={800}>
                                    <li>
                                        <img src={Cross} alt="icon" />
                                        Weak foundation
                                    </li>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn="fadeIn" duration={3} delay={1800}>
                                    <li>
                                        <img src={Cross} alt="icon" />
                                        Sacrifices
                                    </li>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn="fadeIn" duration={3} delay={0}>
                                    <li>
                                        <img src={Cross} alt="icon" />
                                        Low sustainability and scalability
                                    </li>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn="fadeIn" duration={3} delay={1500}>
                                    <li>
                                        <img src={Cross} alt="icon" />
                                        Stuck in same business stage
                                    </li>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn="fadeIn" duration={3} delay={600}>
                                    <li>
                                        <img src={Cross} alt="icon" />
                                        Delayed growth
                                    </li>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn="fadeIn" duration={3} delay={300}>
                                    <li>
                                        <img src={Cross} alt="icon" />
                                        Loss of time, energy, resources & money
                                    </li>
                                </ScrollAnimation>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Our Value Based Model  */}
                <section class="our-value">
                    <div class="dl-container section-padding border-b-grey">
                        <SectionInfo
                            title="Our Value Based Model"
                            content="From day one, we allocate a reliable, professional, and dedicated brand team to bring your vision to life and maximize its value at a base price. Costs grow only as your requirements and brand grow, ensuring cost-efficiency and maximum value."
                            className="mB90"
                            paragraphClass="text-center"
                        />
                        <div class="media-wrapper mB90">
                            <Player
                                src="https://lottie.host/f2131149-fda8-463b-a7e1-6fb48dd34edd/dRSXY94HuA.json"
                                background="transparent"
                                speed={1}
                                style={{ width: '100%', height: 'auto' }}
                                loop
                                autoplay
                            />
                        </div>
                        <div class="characteristics">
                            <ul>
                                <ScrollAnimation animateIn="fadeIn" duration={3} delay={1500}>
                                    <li>
                                        <img src={Check} alt="icon" />
                                        Maximum value
                                    </li>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn="fadeIn" duration={3} delay={1800}>
                                    <li>
                                        <img src={Check} alt="icon" />
                                        Actions to build strong foundation
                                    </li>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn="fadeIn" duration={3} delay={300}>
                                    <li>
                                        <img src={Check} alt="icon" />
                                        High sustainability and scalability at each stage
                                    </li>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn="fadeIn" duration={3} delay={1100}>
                                    <li>
                                        <img src={Check} alt="icon" />
                                        We manage & direct
                                    </li>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn="fadeIn" duration={3} delay={0}>
                                    <li>
                                        <img src={Check} alt="icon" />
                                        Processes and system oriented work
                                    </li>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn="fadeIn" duration={3} delay={2100}>
                                    <li>
                                        <img src={Check} alt="icon" />
                                        Vision, business & customer centric work
                                    </li>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn="fadeIn" duration={3} delay={600}>
                                    <li>
                                        <img src={Check} alt="icon" />
                                        Cost only increases as you grow
                                    </li>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn="fadeIn" duration={3} delay={1000}>
                                    <li>
                                        <img src={Check} alt="icon" />
                                        Right team allocation from day 1
                                    </li>
                                </ScrollAnimation>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* What we do */}
                <section class="what-we-do">
                    <div class="dl-container section-padding border-b-grey">
                        <div class="what-we-do-row">
                            <SectionInfo
                                title="What We Do"
                                content="From conceptualization to research, strategy to design, engineering to storytelling, marketing to expansion, we are your dedicated partner, supporting you at every stage of your business and idea."
                                className="mB90"
                            />

                            <div class="media-wrapper">
                                <Player
                                    src="https://lottie.host/a93c0a9c-c008-4f79-85a9-8d995c3445f0/qHT4MNdtqd.json"
                                    background="transparent"
                                    speed={1}
                                    style={{ width: '100%', height: 'auto' }}
                                    loop
                                    autoplay
                                />
                            </div>
                        </div>
                    </div>
                </section>

                {/* Our partner program  */}
                <section class=" _our_partner_program">
                    <div class="dl-container _our_partner_program_wrapper section-padding border-b-grey">
                        <SectionInfo
                            title="Our Partner Program"
                            content="Our brand design services program to help potential brands and visionaries who are building to
                                    last. And this is how we can add value to your business."
                            className="mB90"
                            paragraphClass="text-center"
                        />
                        <div className="_our_grid_items_row">
                            {ourValueData.map((item, index) => (
                                <GridItem
                                    key={index}
                                    imgSrc={item.imgSrc}
                                    imgAlt={item.imgAlt}
                                    heading={item.heading}
                                    text={item.text}
                                />
                            ))}
                        </div>
                    </div>
                </section>


                {/* Brand partner */}
                <section class="brand-partner">
                    <div class="dl-container section-padding border-b-grey">
                        <SectionInfo
                            title="We’re Brand Design Partner with"
                            content="We only partner with visionaries and businesses that are building to last and with whom we can build long-term relationships. Becoming a brand and building a brand requires patience and continuous efforts day in and day out."
                            className="mB90"
                            paragraphClass="text-center"
                        />
                        <div className="brand-partner-logos">
                            <ul>
                                {brandPartnerLogos.map((logo, index) => (
                                    <li key={index}>
                                        <img src={logo.src} alt={logo.alt} />
                                    </li>
                                ))}
                                <li>
                                    <p>expanding ...</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Our customer values */}
                <section class="our-customer-values">
                    <div class="dl-container _our_partner_program_wrapper section-padding border-b-grey">
                        <SectionInfo
                            title="Our Customer Values"
                            content="To build and maintain positive, transparent & long-lasting relationships"
                            className="mB90"
                            paragraphClass="text-center"
                        />
                        <div className="_our_grid_items_row">
                            {customerValueData.map((item, index) => (
                                <GridItem
                                    key={index}
                                    imgSrc={item.imgSrc}
                                    imgAlt={item.imgAlt}
                                    heading={item.heading}
                                    text={item.text}
                                />
                            ))}
                        </div>
                    </div>
                </section>


                {/* Our Brand Initiatives */}
                <section class="our-brand-initiatives">
                    <div class="dl-container section-padding border-b-grey">
                        <SectionInfo
                            title="Our Brands & Initiatives"
                            content="Along with helping visionaries & businesses, we are continuously putting our efforts towards building around our vision through our sub-brands and initiatives"
                            className="mB50"
                            paragraphClass="text-center"
                        />
                        <div class="our-brand-logos mB50">
                            <ul>
                                <li><img src={DarklionPro} alt="darklion-pro" /></li>
                                <li><Link target="_blank" to="https://darklion.studio/darklion-aid"><img src={DarklionAid} alt="darklion-aid" /></Link></li>
                                <li><Link to="https://edu.darklion.studio" target="_blank"><img src={DarklionEdu} alt="darklion-edu" /></Link></li>
                                <li><Link to="https://thebrandconcept.darklion.studio" target="_blank"><img src={DarklionTbc} alt="darklion-tbc" /></Link></li>
                            </ul>
                        </div>
                        <div class="our-team">
                            <figure>
                                <img src={OurTeam} alt="darklion-studio-team-group" />
                            </figure>
                        </div>
                    </div>
                </section>

                {/* FAQs */}
                <section class="faqs">
                    <div class="dl-container section-padding border-b-grey pB0">
                        <SectionInfo
                            title="FAQs"
                            content="Still have doubts, here are some frequently asked questions or drop us your query at roar@darklion.studio"
                            className="mB60"
                            paragraphClass="text-center"
                        />
                        <div className="dl-accordian">
                            <ul>
                                {faqItems.map((item, index) => (
                                    <li onMouseEnter={mouseInFaqFunction} onMouseLeave={mouseFaqOutFunction}
                                        key={index}
                                        className={activeIndex === index ? 'active' : null}
                                        onClick={() => handleClick(index)}
                                    >
                                        <h5>{item.title}</h5>
                                        <svg width="16px" height="16px" viewBox="0 0 86 86">
                                            <path d="M21.003,80.094c-1.338,1.352-1.338,3.541,0,4.893c1.337,1.35,3.506,1.352,4.845,0l39.149-39.539
                                                    c1.338-1.352,1.338-3.543,0-4.895L25.848,1.014c-1.339-1.352-3.506-1.352-4.845,0c-1.338,1.352-1.338,3.541-0.001,4.893L56.706,43
                                                    L21.003,80.094z"></path>
                                        </svg>
                                        <p>{item.content}</p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Let's Initiate */}
                <section class="dl-block _partner-us border-b-grey section-padding">
                    <div class="dl-container">
                        <div class="section-info text-center">
                            <h2>
                                Let’s initiate the discussion to determine whether <span> we’re right fit for each other.</span>
                            </h2>
                            <p>Don’t worry—we won't jump straight into the commitment. We will only commit if you see value in our work and if we are a good fit during the trial period for a long-term relationship.</p>
                            <div>
                                <Link to="javascript:void(0);" onMouseEnter={onMouseEnterBelowButton} aria-label="Contact DarkLion Studio" id="footer_modal" title="Let’s Initiate" class={`cursor-scale dl-button white mT30 _partner-us_btn `} data-modal="toggle" onClick={modalClick}>Let’s Initiate</Link>
                            </div>
                        </div>
                    </div>
                </section>


                {/*Let's Initiate Modal*/}
                {modalVisibleVar && (
                    <div onMouseEnter={mouseFunction} onMouseLeave={mouseOutFunction} id="request-modal" class="modal fade" data-modal="toggle">
                        <div class="modal-dialog w800">
                            <div class="modal-content">

                                <div class="modal-head">
                                    <h3 class="modal-headline">Contact Information</h3>
                                    <div class="modal-close-wrapper">
                                        <button type="button" class="modal-close" aria-hidden="true" onclick="resetFormFunc()" onClick={modalClick}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                <path d="M37.463 12.1724L12.1723 37.463" stroke="black" stroke-width="2.84672">
                                                </path>
                                                <path d="M12.1722 12.1721L37.4629 37.4628" stroke="black" stroke-width="2.84672">
                                                </path>
                                            </svg>
                                        </button>
                                    </div>
                                    <p class="modal-instruction">Please provide us with some basic information to help us get in
                                        touch with you.</p>
                                </div>

                                <div class="modal-body">
                                    <form method='POST' onSubmit={handleSubmit(onSubmit)}>
                                        <div class="dl-input-group">
                                            <input type="text" class={`dl-input ` + (errors.fullname ? 'error' : '') + ' ' + (watch('fullname') ? 'has-content' : '')} autoComplete="off" {...register('fullname', { required: "Name field is required." })} />
                                            <label class="input-label">Full Name *</label>
                                            <span class="focus-border"></span>
                                            {errors.fullname && <label className="dl-input-errors block">{errors.fullname.message}</label>}
                                        </div>

                                        <div class="dl-input-group">
                                            <input type="text" id="email" class={`dl-input` + (errors.email ? 'error' : '') + ' ' + (watch('email') ? 'has-content' : '')} autoComplete="off"   {...register("email", {
                                                required: 'Email is required', pattern: {
                                                    value: /\S+@\S+\.\S+/,
                                                    message: "Entered value does not match email format"
                                                }
                                            })} />
                                            <label class="input-label">Email Adress *</label>
                                            <span class="focus-border"></span>
                                            {errors.email && <label className="dl-input-errors block">{errors.email.message}</label>}
                                        </div>


                                        <div class="dl-input-group">

                                            <input type="text" class={`dl-input` + (errors.company_name ? 'error' : '') + ' ' + (watch('company_name') ? 'has-content' : '')} autoComplete="off"  {...register('company_name', { required: "Company name field is required." })} />

                                            <label class="input-label">Company Name *</label>
                                            <span class="focus-border"></span>
                                            {errors.company_name && <label className="dl-input-errors block">{errors.company_name.message}</label>}
                                        </div>
                                        <div class="dl-input-group dl-select-group">
                                            <div class="form caret-none" onClick={companyPhaseDropDownClick}>
                                                <input class="chosen-value selected-value" value={companyPhaseValue} {...register('company_phase', { required: 'Please select at least one option.' })} transparent="" type="text" placeholder="Company Phase *" />
                                                <ul className={"selected-list " + (companyPhaseDropDownToggle ? 'open' : '')} >
                                                    <li onClick={companyPhaseClick}>Ideation</li>
                                                    <li onClick={companyPhaseClick}>Startup</li>
                                                    <li onClick={companyPhaseClick}>Growth</li>
                                                    <li onClick={companyPhaseClick}>Maturity</li>
                                                    <li onClick={companyPhaseClick}>Enterprise</li>
                                                </ul>
                                            </div>
                                            <input type="hidden" name="company_size" value="size" />
                                            <span class="focus-border"></span>
                                            <i>
                                                <svg width="20" height="20" viewBox="0 0 36 20" fill="none">
                                                    <path d="M1 1L18.2099 18L35 0.999999" stroke="black" stroke-width="2"></path>
                                                </svg>
                                            </i>
                                            {errors.company_phase ? <label for="" class="dl-input-errors block" id="company-size-errors">{errors.company_phase.message}</label> : ''}
                                        </div>
                                        <div class="dl-input-group input-other">
                                            <input type="text" class={`dl-input` + (errors.role ? 'error' : '') + ' ' + (watch('role') ? 'has-content' : '')} {...register('role', { required: 'Role field is required' })} autoComplete="off" />
                                            <label class="input-label">Your Role In Your Company *</label>
                                            <span class="focus-border"></span>
                                            {errors.role ? <label for="" class="dl-input-errors block">{errors.role.message}</label> : ''}
                                        </div>

                                        <div class="dl-input-group input-other">
                                            <input type="text" class={`dl-input` + (errors.location ? 'error' : '') + ' ' + (watch('location') ? 'has-content' : '')} {...register('location', { required: 'Location field is required.' })} autoComplete="off" />
                                            <label class="input-label">Your Location *</label>
                                            <span class="focus-border"></span>
                                            {errors.location ? <label for="" class="dl-input-errors block">{errors.location.message}</label> : ''}
                                        </div>
                                        <div class="dl-input-group">
                                            <textarea class={`dl-input v-resize` + (errors.about_project ? 'error' : '') + ' ' + (watch('about_project') ? 'has-content' : '')} rows="4" autoComplete="off" name="about_project" max="500" min="80" placeholder="Please provide an overview description of your brand requirements and objectives for this RFP *" {...register('about_project', { required: 'Please write down some overview of your project.' })}></textarea>
                                            <span class="focus-border"></span>
                                            {errors.about_project ? <label for="" class="dl-input-errors block">{errors.about_project.message}</label> : ''}
                                        </div>
                                        <p class="ps-txt">PS: This information will help us tailor our response to meet your needs and ensure
                                            we understand the scope of work involved.</p>

                                        {submission ? <div class="dl-input-group d-flex mT20 flex-ac">
                                            <i class="dl-icon">
                                                <svg width="50" height="50" viewBox="0 0 137 137" fill="none">
                                                    <circle cx="68.5" cy="68.5" r="47.5" stroke="black" stroke-width="4"></circle>
                                                    <path d="M37 65.9737L57.365 87L99 53" stroke="black" stroke-width="4"></path>
                                                </svg>
                                            </i>
                                            <p class="mB0">Request submitted successfully. We will get in touch soon!</p>
                                        </div> : ''}


                                        <div id="captcha_element" data-sitekey="6Lfu7Q0qAAAAAM-QyUKm-mH0gU-mhgzmBSbgLdiG" class="mB30"></div>
                                        <p id='captcha_element_error'></p>

                                        <div class="dl-submit-group">
                                            <button name="submitBtn" class="loader-btn dl-button black dlxss-block" >
                                                {requestSubmissionInProgress ? <span class="loader"></span> : 'SUBMIT RFP'}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


            </div>
        </Fragment>
    )
}

export default BrandDesignPartner;
