// src/config.js
const config = {
    development: {
        frontendBaseUrl: 'https://versionupdate.darklion.studio',
        apiBaseUrl: 'https://admin.darklion.studio', // or whatever your local URL is
    },
    production: {
        frontendBaseUrl: 'https://darklion.studio',
        apiBaseUrl: 'https://admin.darklion.studio',
    },
};

export default config;
