import React, { Fragment} from 'react'
import { register } from 'swiper/element/bundle';
import HiringProcess from '../components/HiringProcess/HiringProcess';

import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import Slider from '../components/Slider/Slider.jsx';

//culture images
import CultureImage1 from '../assets/img/team/culture/darklion-studio-culture-1.jpg';
import CultureImage2 from '../assets/img/team/culture/darklion-studio-culture-2.jpg';
import CultureImage3 from '../assets/img/team/culture/darklion-studio-culture-3.jpg';
import CultureImage4 from '../assets/img/team/culture/darklion-studio-culture-4.jpg';
import { MetaInfo } from '../components/Seo/MetaInfo.jsx';

register();
function Careers() {

    const CultureImages = [
        { image: CultureImage2, alt: "DarkLion Studio Culture" },
        { image: CultureImage1, alt: "DarkLion Studio Culture" },
        { image: CultureImage3, alt: "DarkLion Studio Culture" },
        { image: CultureImage4, alt: "DarkLion Studio Culture" },
    ]

    const options = {
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 30,
        navigation: true,
        autoplay: true,
        pagination: true,
        breakpoints: {
            576: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 1.5,
            },
        },
    };

    return (
        <Fragment>
            <MetaInfo page_name="careers" />
            <section className="dl-page-banner dl-block dl-block-dark text-center">
                <div className="dl-container">
                    <div className="dl-row">
                        <div className="dlxs-12 dlsm-12 dllg-12">
                            <ScrollAnimation animateIn="fadeInUp">
                                <h1 className="dl-page-title font-body">Job Openings at DarkLion Studio</h1>
                                <h2 className="mT0 mB20 font-w-600 font-62">Allow your passion, to be your profession</h2>
                                <p className="font-l">Join our team of artists on the mission to build life-changing digital experiences. We believe that skills can be acquired but the right attitude can't be. We appoint for the right attitude.</p>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <img src={require('../assets/img/team/culture/darklion-studio-mission-1.jpg')} alt="Join DarkLion Studio" className="dl-img-responsive dl-pos-in fadeInUp animate mT30" />
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>

            <section className="dl-block">
                <div className="dl-container overflow-hide">
                    <div className="dl-row">
                        <div className="dlxs-12 dlsm-12 dllg-12 text-left dlsm-center">
                            <h3> Your next career move is waiting</h3>
                            <h4 className="font-body font-p font-l mB20 dlxs-mB0">Explore your dream career & job opportunities at DarkLion Studio for which you are passionate about:</h4>
                        </div>

                        <div class="dlxs-12 dlsm-12 dllg-12">

                            <Link class="dl-job-card d-flex anchor-none focus-hover dlsm-flex-col dlsm-center dlsm-flex-ac" to={'/careers/front-end-developer'}>
                                <i>
                                    <svg width="60" height="60" viewBox="0 0 137 137" fill="none">
                                        <path d="M111 24H24V112.5L111 24Z" stroke="black" stroke-width="4" />
                                        <path d="M112 112L24.5 24.5" stroke="black" stroke-width="4" />
                                    </svg>
                                </i>

                                <div class="w-100">
                                    <div class="d-flex">
                                        <h4 class="font-xl flex-grow-1">Front-end Developer</h4>

                                         {/* <span class="hidden-dlsm dl-button primary small light round disabled">
                                            Soon
                                        </span>  */}
                                        <span>
                                            <svg width="50" height="50" viewBox="0 0 61 61" fill="none">
                                                <path d="M37.7988 50L58.7988 29" stroke="black" stroke-width="4"></path>
                                                <path d="M37 10L58 31" stroke="black" stroke-width="4"></path>
                                                <path d="M1 30.2012L58 30.2012" stroke="black" stroke-width="4"></path>
                                            </svg>
                                        </span>
                                    </div>

                                    <ul class="mB0 d-flex dlsm-flex-jcc ul-none dlsm-mB20 dlsm-flex-wrap">
                                        <li class="d-flex flex-ac mR20">
                                            <svg width="30" height="30" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="68.5002" cy="49.0608" r="8.26" stroke="black" stroke-width="6" />
                                                <circle cx="68.5" cy="48.543" r="38.5" stroke="black" stroke-width="6" />
                                                <path d="M69.0859 89.043L69.0859 128.956" stroke="black" stroke-width="6" />
                                            </svg>
                                            <p class="mB0 mL5">Mohali, On-site</p>
                                        </li>

                                        <li class="d-flex flex-ac mR20">
                                            <svg width="30" height="30" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="35" y="19" width="67" height="100" stroke="black" stroke-width="5" />
                                                <rect x="50" y="90" width="14" height="29" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 48 71)" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 48 43)" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 74 71)" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 74 99)" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 74 43)" stroke="black" stroke-width="5" />
                                            </svg>
                                            <p class="mB0 mL5">Full-time</p>
                                        </li>

                                        <li class="d-flex flex-ac mR20">
                                            <svg width="30" height="30" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="68.5" cy="68.5" r="48.5" stroke="black" stroke-width="5" />
                                                <path d="M51.666 44.9336H66.6239M93.0279 44.9336H66.6239M51.666 61.6418H80.5413M93.0279 61.6418H80.5413M66.6239 44.9336C71.2631 45.723 80.5413 50.1697 80.5413 61.6418M80.5413 61.6418C80.8882 66.0272 77.4197 77.6923 63.6324 77.6923H54.2674L80.5413 103.61" stroke="black" stroke-width="6" />
                                            </svg>
                                            <p class="mB0 mL5">Competitive salary</p>
                                        </li>

                                        <li class="d-flex flex-ac mR20">
                                            <svg width="30" height="30" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M69.9433 100.02L38.5052 116L43.8496 82.4733L18.3848 57.72L54.5386 51.7667L69.9433 22L85.348 51.7667L119.616 57.72L94.465 82.4733L99.4951 116L69.9433 100.02Z" stroke="black" stroke-width="6" />
                                            </svg>
                                            <p class="mB0 mL5">2 to 5 years</p>
                                        </li>
                                    </ul>
                                </div>

                                <div class="focus-border"></div>
                            </Link>

                            <Link class="dl-job-card d-flex anchor-none focus-hover dlsm-flex-col dlsm-center dlsm-flex-ac" to="/careers/career-ui-designer">
                                <i>
                                    <svg width="60" height="60" viewBox="0 0 137 137" fill="none">
                                        <path d="M24.1055 24L68.1028 112.849L113.382 24" stroke="black" stroke-width="4" />
                                        <path d="M13 56.4844L123.207 56.4844" stroke="black" stroke-width="4" />
                                    </svg>
                                </i>

                                <div class="w-100">
                                    <div class="d-flex">
                                        <h4 class="font-xl flex-grow-1">UI & Interaction Designer</h4>

                                        {/* <span class="hidden-dlsm dl-button primary small light round disabled">
                                            Soon
                                        </span> */}
                                        <span>
                                            <svg width="50" height="50" viewBox="0 0 61 61" fill="none">
                                                <path d="M37.7988 50L58.7988 29" stroke="black" stroke-width="4"></path>
                                                <path d="M37 10L58 31" stroke="black" stroke-width="4"></path>
                                                <path d="M1 30.2012L58 30.2012" stroke="black" stroke-width="4"></path>
                                            </svg>
                                        </span>
                                    </div>

                                    <ul class="mB0 d-flex dlsm-flex-jcc ul-none dlsm-mB20 dlsm-flex-wrap">
                                        <li class="d-flex flex-ac mR20">
                                            <svg width="30" height="30" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="68.5002" cy="49.0608" r="8.26" stroke="black" stroke-width="6" />
                                                <circle cx="68.5" cy="48.543" r="38.5" stroke="black" stroke-width="6" />
                                                <path d="M69.0859 89.043L69.0859 128.956" stroke="black" stroke-width="6" />
                                            </svg>
                                            <p class="mB0 mL5">Mohali, On-site</p>
                                        </li>

                                        <li class="d-flex flex-ac mR20">
                                            <svg width="30" height="30" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="35" y="19" width="67" height="100" stroke="black" stroke-width="5" />
                                                <rect x="50" y="90" width="14" height="29" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 48 71)" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 48 43)" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 74 71)" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 74 99)" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 74 43)" stroke="black" stroke-width="5" />
                                            </svg>
                                            <p class="mB0 mL5">Full-time</p>
                                        </li>

                                        <li class="d-flex flex-ac mR20">
                                            <svg width="30" height="30" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="68.5" cy="68.5" r="48.5" stroke="black" stroke-width="5" />
                                                <path d="M51.666 44.9336H66.6239M93.0279 44.9336H66.6239M51.666 61.6418H80.5413M93.0279 61.6418H80.5413M66.6239 44.9336C71.2631 45.723 80.5413 50.1697 80.5413 61.6418M80.5413 61.6418C80.8882 66.0272 77.4197 77.6923 63.6324 77.6923H54.2674L80.5413 103.61" stroke="black" stroke-width="6" />
                                            </svg>
                                            <p class="mB0 mL5">Competitive salary</p>
                                        </li>

                                        <li class="d-flex flex-ac mR20">
                                            <svg width="30" height="30" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M69.9433 100.02L38.5052 116L43.8496 82.4733L18.3848 57.72L54.5386 51.7667L69.9433 22L85.348 51.7667L119.616 57.72L94.465 82.4733L99.4951 116L69.9433 100.02Z" stroke="black" stroke-width="6" />
                                            </svg>
                                            <p class="mB0 mL5">3-5+ years</p>
                                        </li>
                                    </ul>
                                </div>

                                <div class="focus-border"></div>
                            </Link>

                            <Link class="dl-job-card d-flex anchor-none focus-hover dlsm-flex-col dlsm-center dlsm-flex-ac" to="/careers/career-graphic-designer">
                                <i>
                                    <svg width="60" height="60" viewBox="0 0 137 137" fill="none">
                                        <path d="M34 93.5593L103 54.7737L34 10.5254" stroke="black" stroke-width="4" />
                                        <path d="M35.168 127.474V46.7793L102.414 87.1268L35.168 127.474Z" stroke="black" stroke-width="4" />
                                    </svg>
                                </i>

                                <div class="w-100">
                                    <div class="d-flex">

                                        <h4 class="font-xl flex-grow-1">Graphic Designer</h4>



                                        {/* <span class="hidden-dlsm dl-button primary small light round disabled">
                                            Soon
                                        </span> */}
                                        <span>
                                            <svg width="50" height="50" viewBox="0 0 61 61" fill="none">
                                                <path d="M37.7988 50L58.7988 29" stroke="black" stroke-width="4"></path>
                                                <path d="M37 10L58 31" stroke="black" stroke-width="4"></path>
                                                <path d="M1 30.2012L58 30.2012" stroke="black" stroke-width="4"></path>
                                            </svg>
                                        </span>
                                    </div>

                                    <ul class="mB0 d-flex dlsm-flex-jcc ul-none dlsm-mB20 dlsm-flex-wrap">
                                        <li class="d-flex flex-ac mR20">
                                            <svg width="30" height="30" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="68.5002" cy="49.0608" r="8.26" stroke="black" stroke-width="6" />
                                                <circle cx="68.5" cy="48.543" r="38.5" stroke="black" stroke-width="6" />
                                                <path d="M69.0859 89.043L69.0859 128.956" stroke="black" stroke-width="6" />
                                            </svg>
                                            <p class="mB0 mL5">Mohali, on-site</p>
                                        </li>

                                        <li class="d-flex flex-ac mR20">
                                            <svg width="30" height="30" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="35" y="19" width="67" height="100" stroke="black" stroke-width="5" />
                                                <rect x="50" y="90" width="14" height="29" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 48 71)" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 48 43)" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 74 71)" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 74 99)" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 74 43)" stroke="black" stroke-width="5" />
                                            </svg>
                                            <p class="mB0 mL5">Full-time</p>
                                        </li>

                                        <li class="d-flex flex-ac mR20">
                                            <svg width="30" height="30" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="68.5" cy="68.5" r="48.5" stroke="black" stroke-width="5" />
                                                <path d="M51.666 44.9336H66.6239M93.0279 44.9336H66.6239M51.666 61.6418H80.5413M93.0279 61.6418H80.5413M66.6239 44.9336C71.2631 45.723 80.5413 50.1697 80.5413 61.6418M80.5413 61.6418C80.8882 66.0272 77.4197 77.6923 63.6324 77.6923H54.2674L80.5413 103.61" stroke="black" stroke-width="6" />
                                            </svg>
                                            <p class="mB0 mL5">Competitive salary</p>
                                        </li>

                                        <li class="d-flex flex-ac mR20">
                                            <svg width="30" height="30" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M69.9433 100.02L38.5052 116L43.8496 82.4733L18.3848 57.72L54.5386 51.7667L69.9433 22L85.348 51.7667L119.616 57.72L94.465 82.4733L99.4951 116L69.9433 100.02Z" stroke="black" stroke-width="6" />
                                            </svg>
                                            <p class="mB0 mL5">3+ years</p>
                                        </li>
                                    </ul>
                                </div>

                                <div class="focus-border"></div>
                            </Link>

                            <Link class="dl-job-card d-flex anchor-none focus-hover dlsm-flex-col dlsm-center dlsm-flex-ac" to="/careers/career-digital-marketing">
                                <i>
                                    <svg width="40" height="40" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M113 25L25 113V25L113 113" stroke="black" stroke-width="5" />
                                    </svg>
                                </i>

                                <div class="w-100">
                                    <div class="d-flex">

                                        <h4 class="font-xl flex-grow-1">Digital Marketing</h4>



                                        {/* <span class="hidden-dlsm dl-button primary small light round disabled">
                                            Soon
                                        </span> */}
                                        <span>
                                            <svg width="50" height="50" viewBox="0 0 61 61" fill="none">
                                                <path d="M37.7988 50L58.7988 29" stroke="black" stroke-width="4"></path>
                                                <path d="M37 10L58 31" stroke="black" stroke-width="4"></path>
                                                <path d="M1 30.2012L58 30.2012" stroke="black" stroke-width="4"></path>
                                            </svg>
                                        </span>
                                    </div>

                                    <ul class="mB0 d-flex dlsm-flex-jcc ul-none dlsm-mB20 dlsm-flex-wrap">
                                        <li class="d-flex flex-ac mR20">
                                            <svg width="30" height="30" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="68.5002" cy="49.0608" r="8.26" stroke="black" stroke-width="6" />
                                                <circle cx="68.5" cy="48.543" r="38.5" stroke="black" stroke-width="6" />
                                                <path d="M69.0859 89.043L69.0859 128.956" stroke="black" stroke-width="6" />
                                            </svg>
                                            <p class="mB0 mL5">Mohali, on-site</p>
                                        </li>

                                        <li class="d-flex flex-ac mR20">
                                            <svg width="30" height="30" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="35" y="19" width="67" height="100" stroke="black" stroke-width="5" />
                                                <rect x="50" y="90" width="14" height="29" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 48 71)" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 48 43)" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 74 71)" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 74 99)" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 74 43)" stroke="black" stroke-width="5" />
                                            </svg>
                                            <p class="mB0 mL5">Full-time</p>
                                        </li>

                                        <li class="d-flex flex-ac mR20">
                                            <svg width="30" height="30" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="68.5" cy="68.5" r="48.5" stroke="black" stroke-width="5" />
                                                <path d="M51.666 44.9336H66.6239M93.0279 44.9336H66.6239M51.666 61.6418H80.5413M93.0279 61.6418H80.5413M66.6239 44.9336C71.2631 45.723 80.5413 50.1697 80.5413 61.6418M80.5413 61.6418C80.8882 66.0272 77.4197 77.6923 63.6324 77.6923H54.2674L80.5413 103.61" stroke="black" stroke-width="6" />
                                            </svg>
                                            <p class="mB0 mL5">Competitive salary</p>
                                        </li>

                                        <li class="d-flex flex-ac mR20">
                                            <svg width="30" height="30" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M69.9433 100.02L38.5052 116L43.8496 82.4733L18.3848 57.72L54.5386 51.7667L69.9433 22L85.348 51.7667L119.616 57.72L94.465 82.4733L99.4951 116L69.9433 100.02Z" stroke="black" stroke-width="6" />
                                            </svg>
                                            <p class="mB0 mL5">3+ years</p>
                                        </li>
                                    </ul>
                                </div>

                                <div class="focus-border"></div>
                            </Link>

                            {/* job post */}

                            <Link class="dl-job-card d-flex anchor-none focus-hover dlsm-flex-col dlsm-center dlsm-flex-ac" to="/careers/career-wordpress-developer">
                                <i>
                                    <svg width="60" height="60" viewBox="0 0 137 137" fill="none">
                                        <path d="M111 24H24V112.5L111 24Z" stroke="black" stroke-width="4" />
                                        <path d="M112 112L24.5 24.5" stroke="black" stroke-width="4" />
                                    </svg>
                                </i>

                                <div class="w-100">
                                    <div class="d-flex">
                                        <h4 class="font-xl flex-grow-1">Wordpress Developer</h4>


                                        {/* <span class="hidden-dlsm dl-button primary small light round disabled">
                                            Soon
                                        </span> */}
                                        <span>
                                            <svg width="50" height="50" viewBox="0 0 61 61" fill="none">
                                                <path d="M37.7988 50L58.7988 29" stroke="black" stroke-width="4"></path>
                                                <path d="M37 10L58 31" stroke="black" stroke-width="4"></path>
                                                <path d="M1 30.2012L58 30.2012" stroke="black" stroke-width="4"></path>
                                            </svg>
                                        </span>
                                    </div>

                                    <ul class="mB0 d-flex dlsm-flex-jcc ul-none dlsm-mB20 dlsm-flex-wrap">
                                        <li class="d-flex flex-ac mR20">
                                            <svg width="30" height="30" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="68.5002" cy="49.0608" r="8.26" stroke="black" stroke-width="6" />
                                                <circle cx="68.5" cy="48.543" r="38.5" stroke="black" stroke-width="6" />
                                                <path d="M69.0859 89.043L69.0859 128.956" stroke="black" stroke-width="6" />
                                            </svg>
                                            <p class="mB0 mL5">Mohali, on-site</p>
                                        </li>

                                        <li class="d-flex flex-ac mR20">
                                            <svg width="30" height="30" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="35" y="19" width="67" height="100" stroke="black" stroke-width="5" />
                                                <rect x="50" y="90" width="14" height="29" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 48 71)" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 48 43)" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 74 71)" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 74 99)" stroke="black" stroke-width="5" />
                                                <rect x="2" y="-2" width="12" height="11" transform="matrix(1 0 0 -1 74 43)" stroke="black" stroke-width="5" />
                                            </svg>
                                            <p class="mB0 mL5">Full-time</p>
                                        </li>

                                        <li class="d-flex flex-ac mR20">
                                            <svg width="30" height="30" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="68.5" cy="68.5" r="48.5" stroke="black" stroke-width="5" />
                                                <path d="M51.666 44.9336H66.6239M93.0279 44.9336H66.6239M51.666 61.6418H80.5413M93.0279 61.6418H80.5413M66.6239 44.9336C71.2631 45.723 80.5413 50.1697 80.5413 61.6418M80.5413 61.6418C80.8882 66.0272 77.4197 77.6923 63.6324 77.6923H54.2674L80.5413 103.61" stroke="black" stroke-width="6" />
                                            </svg>
                                            <p class="mB0 mL5">Competitive salary</p>
                                        </li>

                                        <li class="d-flex flex-ac mR20">
                                            <svg width="30" height="30" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M69.9433 100.02L38.5052 116L43.8496 82.4733L18.3848 57.72L54.5386 51.7667L69.9433 22L85.348 51.7667L119.616 57.72L94.465 82.4733L99.4951 116L69.9433 100.02Z" stroke="black" stroke-width="6" />
                                            </svg>
                                            <p class="mB0 mL5">3-5+ years</p>
                                        </li>
                                    </ul>

                                </div>

                                <div class="focus-border"></div>
                            </Link>
                        </div>


                        <div className="dl-row">
                            <div className="dlxs-12 text-center dl-cta">
                                <a target="_blank" href="https://www.linkedin.com/company/darklionstudio" title="Connect On LinkedIn" className="dl-button mT40">
                                    Connect On LinkedIn
                                    <i>
                                        <svg height="25px" viewBox="0 0 512 512" width="25px">
                                            <rect height="328.97" width="106.716" x="17.397" y="166.28" fill=""></rect>
                                            <path d="M414.789,170.138c-1.133-0.355-2.207-0.743-3.396-1.078c-1.438-0.327-2.865-0.6-4.328-0.833     c-5.662-1.139-11.875-1.946-19.148-1.946c-62.211,0-101.678,45.372-114.674,62.894V166.28H166.526v328.97h106.716V315.813     c0,0,80.643-112.628,114.674-29.911V495.25h106.688V273.257C494.604,223.549,460.629,182.13,414.789,170.138L414.789,170.138z      M414.789,170.138" fill=""></path>
                                            <path d="M121.789,69.085c0,28.909-23.373,52.337-52.195,52.337c-28.824,0-52.196-23.429-52.196-52.337     c0-28.903,23.372-52.335,52.196-52.335C98.416,16.75,121.789,40.182,121.789,69.085L121.789,69.085z M121.789,69.085" fill=""></path>
                                        </svg>
                                    </i>
                                </a>

                                <p className="text-center font-s mT20">
                                    Connect with us on LinkedIn to get the latest job opening updates.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="dl-block dl-block-dark dl-aboutUs-slider dl-initiatives text-center _overflowUpto1199">
                <div className="dl-container">
                    <div className="dl-row">
                        <div className="dlxs-12">
                            <h3>What makes us different</h3>
                            <p className="font-l">Passion for what we do and culture that pushes us to do beyond our limits</p>
                        </div>

                        <div className="dlxs-12 dlsm-12 dllg-12">
                            <div className="d-flex flex-wrap">
                                <ScrollAnimation animateIn='fadeInUp' className="dl-icon-card mT30 flex-grid dlsm-mT0">
                                    <i className="dl-icon">
                                        <svg width="135" height="135" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M35.7807 47.9758C34.204 60.445 38.8246 89.0876 69.9209 103.905C70.7752 90.6741 65.1432 60.9658 35.7807 47.9758Z" stroke="white" strokeWidth="3" />
                                            <path d="M101.961 21.5379C90.6169 26.9492 69.0131 46.3147 73.3492 80.4866C84.9432 74.0561 106.897 53.2638 101.961 21.5379Z" stroke="white" strokeWidth="3" />
                                            <path d="M73 77L73 120" stroke="white" strokeWidth="3" />
                                            <path d="M89.5 49.5L74.0002 76.7422" stroke="white" strokeWidth="3" />
                                            <path d="M53 76L68.5954 101.611" stroke="white" strokeWidth="3" />
                                        </svg>
                                    </i>
                                    <h4 className="mT20 dlxs-mT5">Healthy culture</h4>
                                    <p>Respect, equal opportunities, and an environment that encourages you to dream & grow.</p>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn='fadeInUp' className="dl-icon-card mT30 flex-grid dlsm-mT0">
                                    <i className="dl-icon">
                                        <svg width="137" height="137" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M110 70L121 70" stroke="white" strokeWidth="3" />
                                            <path d="M107.287 30.0171L99.2407 38.0635" stroke="white" strokeWidth="3" />
                                            <path d="M27.1724 30.0171L35.2188 38.0635" stroke="white" strokeWidth="3" />
                                            <path d="M61 122L73 122" stroke="white" strokeWidth="3" />
                                            <path d="M67 12V23" stroke="white" strokeWidth="3" />
                                            <path d="M13 70L24 70" stroke="white" strokeWidth="3" />
                                            <circle cx="67" cy="66" r="34.5" stroke="white" strokeWidth="3" />
                                            <path d="M55 113V98C65.3385 101.871 75.3077 99.6129 79 98V113H55Z" stroke="white" strokeWidth="3" />
                                        </svg>
                                    </i>
                                    <h4 className="mT20 dlxs-mT5">We know you can innovate</h4>
                                    <p>We won’t let your creativity die, share your ideas & let’s implement them together.</p>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn='fadeInUp' className="dl-icon-card mT30 flex-grid dlsm-mT0">
                                    <i className="dl-icon">
                                        <svg width="135" height="135" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M56.6095 70.0248L75.1149 51.5193M75.1149 51.5193L77.36 49.2896C82.2582 45.8842 85.8233 46.9497 87.7517 48.8781C90.3929 51.5193 88.9933 55.5732 87.9727 57.1948L66.5432 78.4785L86.3397 58.8167C89.6051 57.1951 92.9981 59.0051 94.0951 60.1165C97.3443 63.4082 96.5213 66.6999 94.0951 68.9516L76.0713 86.8616L90.0133 73.0057C98.0135 72.8435 99.813 78.4785 96.7482 81.7217L77.1832 101.674C75.1259 103.732 69.3654 108.669 69.3654 108.669C53.6947 120.955 46.303 113.344 42.665 110.505L33.5831 101.485L24.5012 92.4648C14.2295 81.924 24.9775 68.9516 28.9912 64.4921L40.0119 53.5464C46.5427 53.06 48.0393 59.4247 47.9713 62.6679M75.1149 51.5193C75.8632 49.9654 76.8297 45.7661 73.8909 43.658C73.068 43.0677 70.1877 41.6007 66.5432 44.2223L47.9713 62.6679M41.6445 68.9516L47.9713 62.6679M85.3194 36.7112L94.0951 27.9951M48.4033 27.9951L57.179 36.7112" stroke="white" strokeWidth="3" />
                                            <path d="M67.3081 109.493C70.9461 112.332 81.2978 112.373 88.5132 105.572C88.5132 105.572 94.2737 100.635 96.331 98.5774L115.896 78.625C118.961 75.3817 117.161 69.7468 109.161 69.9089L104.054 74.9296L113.243 65.8548C115.669 63.6031 116.492 60.3114 113.243 57.0197C112.146 55.9084 108.753 54.0983 105.488 55.7199L101.367 59.7055L107.121 54.098C108.141 52.4764 109.541 48.4226 106.9 45.7813C104.971 43.8529 100.637 42.0121 96.5078 46.1928L94.2627 48.4226" stroke="white" strokeWidth="3" />
                                            <path d="M71.4095 31.6207L71.0969 17.9996" stroke="white" strokeWidth="3" />
                                        </svg>
                                    </i>
                                    <h4 className="mT20 dlxs-mT5">You’re a Rockstar</h4>
                                    <p>We never leave a chance to appreciate your work.</p>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn='fadeInUp' className="dl-icon-card mT30 flex-grid dlsm-mT0">
                                    <i className="dl-icon">
                                        <svg width="135" height="135" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M69.5378 61.3164L54.4007 69.0364L56.974 52.8397L44.7129 40.8813L62.1206 38.0053L69.5378 23.625L76.955 38.0053L93.4544 40.8813L81.3447 52.8397L83.7667 69.0364L69.5378 61.3164Z" stroke="white" strokeWidth="3" />
                                            <path d="M18.375 82.508L38.0533 89.1683L60.759 77.0586L83.0105 79.1778C84.7261 79.3292 88.0361 80.7218 87.5517 85.0813C87.2994 86.4436 85.7655 89.1683 81.6482 89.1683L67.1165 87.6546C66.0569 87.4528 63.8772 87.6546 63.635 90.0765C63.4332 90.8334 63.6653 92.4076 66.2083 92.6498L91.3359 95.5259L113.133 84.0217C114.597 83.4162 117.765 82.9923 118.734 86.1409C119.239 87.2005 119.37 89.7738 115.858 91.5902L95.1202 105.214L58.7911 111.874L18.375 102.64" stroke="white" strokeWidth="3" />
                                            <path d="M91.791 87.2013L103.295 81.6006C104.506 81.096 107.049 80.6621 107.534 82.9629" stroke="white" strokeWidth="3" />
                                        </svg>
                                    </i>
                                    <h4 className="mT20 dlxs-mT5">Perks</h4>
                                    <p>We understand working hard should be fruitful. So we’ve some benefits inside.</p>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn='fadeInUp' className="dl-icon-card mT30 flex-grid dlsm-mT0">
                                    <i className="dl-icon">
                                        <svg width="135" height="135" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M72.0424 38.8582C72.0424 55.1835 59.8188 68.2164 44.9795 68.2164C30.1401 68.2164 17.9165 55.1835 17.9165 38.8582C17.9165 22.5328 30.1401 9.5 44.9795 9.5C59.8188 9.5 72.0424 22.5328 72.0424 38.8582Z" stroke="white" strokeWidth="3" />
                                            <path d="M40.0063 68.8237V75.7094H44.4056M48.8048 68.8237V75.7094H44.4056M44.4056 75.7094C44.8944 77.2396 45.0304 81.2435 41.664 85.0179C37.4561 89.7359 43.3217 96.4941 48.0397 101.085C52.7577 105.675 46.637 113.198 43.3217 115.749" stroke="white" strokeWidth="3" />
                                            <path d="M124.832 75.0721C124.832 91.3974 112.608 104.43 97.769 104.43C82.9296 104.43 70.7061 91.3974 70.7061 75.0721C70.7061 58.7467 82.9296 45.7139 97.769 45.7139C112.608 45.7139 124.832 58.7467 124.832 75.0721Z" stroke="white" strokeWidth="3" />
                                            <path d="M92.7974 105.038V111.923H97.1966M101.596 105.038V111.923H97.1966M97.1966 111.923C97.6854 113.453 98.9683 115.29 95.6018 119.064C94.4643 120.339 92.4786 123.91 97.1966 128.5" stroke="white" strokeWidth="3" />
                                            <path d="M55.4351 84.5078L62.4484 91.521M83.488 35.0327L91.2663 27.2544M10.168 86.038L15.5235 80.6824L27.1272 92.2861L22.2817 97.1316L10.168 86.038ZM69.4616 113.326L63.9785 118.809L69.4616 124.292L74.9447 118.809L69.4616 113.326Z" stroke="white" strokeWidth="3" />
                                        </svg>
                                    </i>
                                    <h4 className="mT20 dlxs-mT5">Surprise parties</h4>
                                    <p>Because we know good food, good vibe, & keeps up the good work.</p>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn='fadeInUp' className="dl-icon-card mT30 flex-grid dlsm-mT0">
                                    <i className="dl-icon">
                                        <svg width="135" height="135" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M53.1396 124.5V88.4773C55.5412 86.6475 60.6187 81.1354 61.7166 73.7249L66.5196 62.7465C66.8627 61.6029 67.0686 58.9726 65.1473 57.6003C63.8894 56.3423 60.6873 55.1987 57.9427 60.688L54.855 65.8338M54.855 65.8338C50.5094 66.7487 42.0925 70.9114 43.1904 80.2431M54.855 65.8338V41.8185C54.855 39.8974 53.0255 38.7308 52.1108 38.3876C49.9154 37.5642 47.5365 38.7307 46.6215 39.4168M29.4673 124.5V88.4773C27.2945 86.5331 22.9488 81.1351 22.9488 75.0969V49.0231C22.8345 47.6507 23.2919 44.9061 26.0365 44.9061C27.5233 44.563 30.4969 45.1806 30.4969 50.3954V60.688V41.8185C30.6113 40.2175 31.5947 37.0154 34.6138 37.0154C35.8718 36.9011 38.4563 37.3585 38.7307 40.1031V60.688V37.0154C39.1879 35.872 40.7199 33.6536 43.1904 33.9281C44.3341 33.928 46.6215 34.5453 46.6215 37.0154V39.4168M46.6215 60.688V39.4168" stroke="white" strokeWidth="3" />
                                            <path d="M108.567 124.5V80.5742C111.501 78.343 117.704 71.6216 119.045 62.5854L124.912 49.1984C125.331 47.8039 125.583 44.5966 123.236 42.9232C121.699 41.3893 117.788 39.9948 114.435 46.6883L110.663 52.9631M110.663 52.9631C105.354 54.0787 95.0719 59.1546 96.413 70.5336M110.663 52.9631V23.6792C110.663 21.3366 108.428 19.914 107.31 19.4955C104.628 18.4915 101.722 19.9139 100.605 20.7506M79.6488 124.5V80.5742C76.9944 78.2034 71.6857 71.6212 71.6857 64.2584V32.4643C71.546 30.7909 72.1048 27.4442 75.4577 27.4442C77.274 27.0258 80.9065 27.7788 80.9065 34.1377V46.6883V23.6792C81.0462 21.7269 82.2477 17.8224 85.9358 17.8224C87.4725 17.6829 90.6298 18.2407 90.9651 21.5875V46.6883V17.8224C91.5236 16.428 93.3951 13.723 96.413 14.0577C97.8102 14.0576 100.605 14.8103 100.605 17.8224V20.7506M100.605 46.6883V20.7506" stroke="white" strokeWidth="3" />
                                            <path d="M60.3447 30.84L68.2355 22.9492" stroke="white" strokeWidth="3" />
                                            <path d="M40.1032 28.0954V13M20.8909 32.2123L12.314 23.6354" stroke="white" strokeWidth="3" />
                                        </svg>
                                    </i>
                                    <h4 className="mT20 dlxs-mT5">Volunteer time</h4>
                                    <p>We believe a small act of kindness can make a big difference. <Link to="/about" className="dl-anchor white">Our team is here.</Link></p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="dl-container-fluid mT40  dark-bg-slider">
                    < Slider slides={CultureImages} options={options} />

                </div>
            </section>

            <HiringProcess />

            <div className="dl-block _overflowUpto1199 _career_page dl-block-dark dl-block-cta d-flex flex-jc dlxs-flex-col dlsm-p0 dlxs-p0 dlxss-center">
                <a href="mailto:archers@darklion.studio" title="Email us for any query" className="d-flex flex-jc left anchor-none dlxss-flex-col">
                    <div className="text">
                        <p>If you have any query, email us at</p>
                        <div className="dl-h4-title">archers@darklion.studio</div>
                    </div>

                    <span className="arrow">
                        <svg width="50" height="50" viewBox="0 0 61 61" fill="none">
                            <path d="M37.7988 50L58.7988 29" stroke="white" strokeWidth="4" />
                            <path d="M37 10L58 31" stroke="white" strokeWidth="4" />
                            <path d="M1 30.2012L58 30.2012" stroke="white" strokeWidth="4" />
                        </svg>
                    </span>
                </a>

                <Link to="/about" title="Read our story" className="d-flex right flex-jc anchor-none dlxs-p0 dlxss-center dlxss-flex-col">
                    <div className="text">
                        <p>What’s our company about?</p>
                        <div className="dl-h4-title">Read our story</div>
                    </div>

                    <span className="arrow">
                        <svg width="50" height="50" viewBox="0 0 61 61" fill="none">
                            <path d="M37.7988 50L58.7988 29" stroke="white" strokeWidth="4" />
                            <path d="M37 10L58 31" stroke="white" strokeWidth="4" />
                            <path d="M1 30.2012L58 30.2012" stroke="white" strokeWidth="4" />
                        </svg>
                    </span>
                </Link>
            </div>
        </Fragment>
    )
}

export default Careers